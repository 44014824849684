import { Helmet } from "react-helmet";
import { useState } from "react";
import axios from "axios";

// css (styles)
import "../css/ContactUs.css";
const ContactUs = () => {
	const [name, setName] = useState();
	const [phone, setPhone] = useState();
	const [email, setEmail] = useState();
	const [subject, setSubject] = useState();
	const [message, setMessage] = useState();
	const [msg, setMsg] = useState();

	const checkInput = (s) => {
		return s
			.replace(/&/g, "&amp;")
			.replace(/</g, "&lt;")
			.replace(/"/g, "&quot;");
	};
	const sendMessage = () => {
		const data = {
			name: checkInput(name),
			phone: checkInput(phone),
			email: checkInput(email),
			subject: checkInput(subject),
			message: checkInput(message),
		};

		axios.post("/contact-us", data);
		alert("message sent");
		setMsg("message sent");
		console.log("message sent");
	};
	return (
		<>
			<Helmet>
				<title>Contact Us</title>
				<meta
					name="description"
					content="Have any questions or suggestions? The employees of Ratar will respond with in 24hours."
				/>
				<link
					href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
					rel="stylesheet"
					integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
					crossorigin="anonymous"
				></link>
				<link
					rel="stylesheet"
					href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.css"
				></link>
			</Helmet>
			<div className="contact-us__container">
				<div className="contact-us__wrapper animated bounceInLeft">
					<div className="company-info">
						<h3>Other ways to contact us</h3>
						<ul>
							<li>
								<i className="fa fa-road"></i>
								<p> Reykjavik, Iceland</p>
							</li>
							<li>
								<i className="fa fa-phone"></i>
								<p> (00354) 555-5555</p>
							</li>
							<li>
								<i className="fa fa-envelope"></i>
								<p> haukur@ratar.org</p>
							</li>
						</ul>
					</div>
					<div className="contact">
						<h3>Email Us</h3>
						<span className="msg">
							<p>{msg}</p>
						</span>
						<form onSubmit={sendMessage}>
							<p>
								<label htmlFor="name">name</label>
								<input
									type="text"
									name="name"
									placeholder="Magnus Magnussen"
									value={name}
									onChange={(e) => setName(e.target.value)}
									required
								/>
							</p>
							<p>
								<label htmlFor="phone">phone</label>
								<input
									type="tel"
									name="phone"
									placeholder="00354 123 4245"
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
									required
								/>
							</p>
							<p>
								<label htmlFor="email">email</label>
								<input
									type="email"
									name="email"
									placeholder="magnus@gmail.com"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									required
								/>
							</p>
							<p>
								<label htmlFor="subject">subject</label>
								<input
									type="text"
									name="subject"
									placeholder="Dear Haukur..."
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									required
								/>
							</p>
							<p className="full">
								<label htmlFor="message">message</label>
								<textarea
									name="message"
									rows="5"
									placeholder="It would be helpfull/usefull if..."
									value={message}
									onChange={(e) => setMessage(e.target.value)}
									required
								></textarea>
							</p>
							<p className="full">
								<button type="submit">submit</button>
							</p>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactUs;
