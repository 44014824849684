// libraries
import { Helmet } from "react-helmet";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// styling (css)
import "./css/Home.css";

// hero img
import sky from "./img/sky.png";
import furtherground from "./img/furtherground.png";
import middleground1 from "./img/middleground1.png";
import leftRock from "./img/leftRock.png";
import rightRock from "./img/rightRock.png";

// cards img
import card2 from "./img/card2.jpg";
import card3 from "./img/card3.jpg";

const Home = () => {
	const [tours, setTours] = useState();
	const [news, setNews] = useState();
	const [rendered, setRendered] = useState();

	useEffect(async () => {
		try {
			const respTours = await axios.get("/tours");
			const respNews = await axios.get("/news");
			// first n news tours
			const n = 3;
			const nTours = respTours.data.slice(0, n);
			const nNews = respNews.data.slice(0, n);

			setTours(nTours);
			setNews(nNews);
			setRendered(true);
		} catch (err) {
			// Handle Error Here
			console.error(err);
			setRendered(true);
		}
	}, [rendered]);

	return (
		<>
			<Helmet>
				<title>Ratar</title>
				<meta
					name="description"
					content="Know your way around iceland. Explore every track. Enjoy extreme sports"
				/>
			</Helmet>
			<div className="container">
				<h2 className="main-title">Iceland</h2>
				<img src={sky} className="sky" alt="" />
				<img src={furtherground} className="furtherground" alt="" />
				<img src={middleground1} className="middleground" alt="" />
				<img src={leftRock} className="rock" alt="" />
				<img src={rightRock} className="rock right" alt="" />
			</div>
			<div className="content">
				<h1 className="title content-title">know your way around Iceland</h1>
				<div className="content-cards">
					{tours &&
						tours.map((tour) => (
							<div className="content-card routes">
								<Link to={tour.link} className="content-card__link">
									<div
										class="content-card__img"
										style={{ backgroundImage: `url(${tour.backgroundImg})` }}
									></div>
									<h3>{tour.title}</h3>
								</Link>
							</div>
						))}
				</div>
				<span className="water-mark">iceland</span>
				<h1 className="title news-title">What's happening?</h1>
				<div className="content-cards">
					{news &&
						news.map((news) => (
							<div className="content-card news">
								<Link to={news.link} className="content-card__link">
									<div
										class="content-card__img"
										style={{ backgroundImage: `url(${news.backgroundImg})` }}
									></div>
									<h3>{news.title}</h3>
								</Link>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

export default Home;
