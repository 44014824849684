import { Helmet } from "react-helmet";

const Is = () => {
	<Helmet>
		<title>Is</title>
	</Helmet>;
	return (
		<h1
			style={{
				margin: "auto",
				color: "white",
				position: "absolute",
				top: "40vh",
				left: "40vw",
				fontSize: "3rem",
			}}
		>
			Síða er í vinnslu
		</h1>
	);
};

export default Is;
