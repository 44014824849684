import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "../css/About.css";

import haukur from "../img/haukur.jpg";
const About = () => {
	return (
		<>
			<Helmet>
				<title>About</title>
				<meta name="description" content="What is Ratar? Ratar is..." />
				<link
					href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
					rel="stylesheet"
					integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
					crossorigin="anonymous"
				></link>
			</Helmet>
			<div className="wrapper">
				<div className="about__title">
					<h2>Meet the creator of Ratar</h2>
				</div>

				<main className="about__main">
					<img src={haukur} alt="img of me" />
					<div className="about__container">
						<div className="name">
							<h2 className="name__h2">Sigurður</h2>
							<h2 className="name-green">Haukur</h2>
						</div>
						<div className="line"></div>
						<div className="paragraph">
							<h4 className="logo">Ratar</h4>
							<p>
								I created the site when i was 13 years old. Hope you had a good
								time.
							</p>
						</div>
						<div className="social">
							<Link to="#">
								<i className="fa fa-twitter fa-2x" aria-hidden="true"></i>
							</Link>
							<Link to="#">
								<i className="fa fa-instagram fa-2x" aria-hidden="true"></i>
							</Link>
							<Link to="#">
								<i className="fa fa-facebook fa-2x" aria-hidden="true"></i>
							</Link>
							<Link to="#">
								<i className="fa fa-github fa-2x" aria-hidden="true"></i>
							</Link>
						</div>
					</div>
				</main>
			</div>
		</>
	);
};

export default About;
