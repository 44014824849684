// imports
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// componments
import Footer from "./componments/Footer";
import Nav from "./componments/Nav";

// sites or pages
import Home from "./Home";
import About from "./sites/About";
import ContactUs from "./sites/ContactUs";
import Is from "./sites/Is";
import News from "./sites/News";
import NotFound from "./sites/NotFound";
import Tours from "./sites/Tours";

function App() {
	return (
		<Router>
			<Nav />
			<div className="app">
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
					<Route path="/news">
						<News />
						<Footer />
					</Route>
					<Route path="/tours">
						<Tours />
						<Footer />
					</Route>
					<Route path="/about">
						<About />
						<Footer />
					</Route>
					<Route path="/contact-us">
						<ContactUs />
						<Footer />
					</Route>
					<Route path="/is">
						<Is />
						<Footer />
					</Route>
					<Route exact path="*">
						<NotFound />
					</Route>
				</Switch>
			</div>
		</Router>
	);
}

export default App;
