import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Nav = () => {
	const [open, setOpen] = useState(false);
	return (
		<nav>
			<Link to="/" className="nav__link nav__logo">
				<h4>Rata</h4>
				<span>r</span>
			</Link>
			<div id="nav__menu" className={open ? "active" : ""}>
				<Link className="nav__link" to="/news" onClick={() => setOpen(false)}>
					<p>news</p>
				</Link>
				<Link className="nav__link" to="/tours" onClick={() => setOpen(false)}>
					<p>tours</p>
				</Link>
				<Link className="nav__link" to="/about" onClick={() => setOpen(false)}>
					<p>about</p>
				</Link>
				<Link
					className="nav__link"
					to="/contact-us"
					onClick={() => setOpen(false)}
				>
					<p>contact us</p>
				</Link>
				<Link className="nav__link" to="/is" onClick={() => setOpen(false)}>
					<p>is</p>
				</Link>
			</div>
			<div
				className={open ? "active" : ""}
				id="hamburger"
				onClick={() => {
					open ? setOpen(false) : setOpen(true);
				}}
			>
				<div className="hamburger__div"></div>
				<div className="hamburger__div"></div>
				<div className="hamburger__div"></div>
			</div>
		</nav>
	);
};
export default Nav;
