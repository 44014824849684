import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import axios from "axios";
// css
import "../css/News.css";

// img
import news2 from "../img/card2.jpg";
import news3 from "../img/card3.jpg";

const News = () => {
	const [news, setNews] = useState();
	const [rendered, setRendered] = useState();

	useEffect(async () => {
		try {
			const resp = await axios.get("/news");
			console.log(resp.data[0].title);
			setNews(resp.data);
			setRendered(true);
		} catch (err) {
			// Handle Error Here
			console.error(err);
			setRendered(true);
		}
	}, [rendered]);

	return (
		<>
			<Helmet>
				<title>News</title>
				<meta
					name="description"
					content="know what is going on around iceland"
				/>
				<link
					href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
					rel="stylesheet"
					integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
					crossorigin="anonymous"
				></link>
			</Helmet>

			<div className="wrapper">
				{!rendered && <h1>Loading</h1>}
				{news &&
					news.map((article, i) => (
						<div className={`blog-card ${article.type}`} key={i}>
							<div className="meta">
								<div
									className="photo"
									style={{ backgroundImage: `url(${article.backgroundImg})` }}
								></div>
								<ul className="details">
									<li className="author">
										<Link to="#">{article.author}</Link>
									</li>
									<li className="date">{article.date}</li>
									<li className="tags">
										<ul>
											{article.tags &&
												article.tags.map((tags, i) => (
													<li key={i}>
														<Link to="#">{tags}</Link>
													</li>
												))}
										</ul>
									</li>
								</ul>
							</div>
							<div className="description">
								<h1>{article.title}</h1>
								<h2>{article.description}</h2>
								<p>{article.paragraphs && article.paragraphs[0]}</p>
								<p className="read-more">
									<a href="/en/news/1002">Read More</a>
								</p>
							</div>
						</div>
					))}
			</div>
		</>
	);
};

export default News;
