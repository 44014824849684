const NotFound = () => {
	const style = {
		color: "white",
		textAlign: "center",
	};
	return (
		<div className="wrapper" style={style}>
			<h1>404</h1>
			<p>site not found</p>
		</div>
	);
};

export default NotFound;
