import { Helmet } from "react-helmet";
import axios from "axios";
import { useEffect, useState } from "react";

// css
import "../css/Tours.css";
const Tours = () => {
	const [tours, setTours] = useState();
	const [rendered, setRendered] = useState();

	useEffect(async () => {
		try {
			const resp = await axios.get("/tours");

			setTours(resp.data);
			setRendered(true);
		} catch (err) {
			// Handle Error Here
			console.error(err);
			setRendered(true);
		}
	}, [rendered]);
	return (
		<>
			<Helmet>
				<title>Tours</title>
				<meta
					name="description"
					content="Want to go on a hike? Routes and tours around iceland."
				/>
			</Helmet>
			<div className="wrapper">
				<h1 className="tours__h1">Wondering where to go next?</h1>
				<div className="tours--cards">
					{tours &&
						tours.map((tour, i) => (
							<div
								key={i}
								className="tour--card"
								style={{ backgroundImage: `url(${tour.backgroundImg})` }}
							>
								<h4 className="tour--card__h4">{tour.title}</h4>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

export default Tours;
