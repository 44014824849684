import "../css/Footer.css";

const Footer = () => {
	const today = new Date();
	const year = today.getFullYear();

	return (
		<footer>
			<p>Ratar Copyright&#169; {year}</p>
		</footer>
	);
};
export default Footer;
